import React, { Component } from "react";
import "antd/dist/antd.css";
import { withRouter } from "react-router";
import { Spin, Row, Col, Card, Rate, Tabs, Button, Collapse } from "antd";
import moment from "moment";
import Carousel from "react-elastic-carousel";
import { list, noAuthGetDetails } from "../../api/Apis";

const { Panel } = Collapse;

const { TabPane } = Tabs;

class UsersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoadingStatus: false,
      analyticsData: "",
      pendingLeads: [],
      inProgressLeads: [],
      completedLeads: [],
      userSubscription: "",
      totalFavCount: 0,
      visits: 0,
      spendingHours: 0,
      spendingMins: 0,
      averageTimeOnSite: 0,
      activeCollapse:"1"
    };
  }

  componentDidMount = () => {
    this.getUserInfo();
    this.getLeads();
    this.getAllFav();
    this.getPropertyStats();
  };
  getPropertyStats = () => {
    let reqQuery = {
      userId: this.props.match.params.id,
    };
    list("getPropertyStats", reqQuery).then((response) => {
      response.data.map((each,index)=>each["key"] = index+1)
      this.setState({
        propertyStatastics: response.data,
      });
    });
  };
  getUserInfo = async () => {
    this.setState({
      dataLoadingStatus: true,
    });
    const userId = this.props.match.params && this.props.match.params.id;

    noAuthGetDetails("getUsers", userId).then((result) => {
      if (result.success) {
        this.getPlans(result.data.userProfile, result.data.subscriptionPack);
        let visits = result.data.totalVisits?.reduce((tot, arr) => {
          // return the sum with previous value
          return tot + arr.visited;

          // set initial value as 0
        }, 0);
        let totalHours = [];
        let totalMin = [];
        result.data.totalVisits && result.data.totalVisits.map((eachDate) => {
          let hours = eachDate.totalTime?.reduce((tot, arr) => {
            console.log(tot, arr.Hours, ";;;;;", eachDate.totalTime);
            return tot + arr.Hours;
          }, 0);
          hours && totalHours.push(hours);

          let mins = eachDate.totalTime?.reduce((tot, arr) => {
            return tot + arr.Mins;
          }, 0);
          mins && totalMin.push(mins);
          return true
        });

        let spendingHours = 0;
        let spendingMins = 0;
        spendingHours =
          totalHours &&
          totalHours.length > 0 &&
          totalHours.reduce((tot, arr) => {
            return tot + arr;
          });
        spendingMins =
          totalMin &&
          totalMin.length > 0 &&
          totalMin.reduce((tot, arr) => {
            return tot + arr;
          });
        let hoursLeft = Math.floor(spendingMins / 60);
        let minsLeft = Math.floor(spendingMins % 60);
        spendingMins = minsLeft;
        spendingHours = spendingHours + hoursLeft;
        let averageTimeOnSite = {};
        let avg = (spendingHours * 60 + spendingMins) / visits;
        avg = Math.floor(avg);
        let avg_hour, avg_min;
        if (avg > 60) {
          avg_hour = Math.floor(avg / 60);
          avg_min = Math.floor(avg % 60);
        } else {
          avg_hour = 0;
          avg_min = avg > 0 ? avg : 0;
        }
        averageTimeOnSite = {
          avg_hour,
          avg_min,
        };
        this.setState({
          dataLoadingStatus: false,
          showAnalytics: true,
          analyticsData: result.data,
          visits,
          spendingHours,
          spendingMins,
          averageTimeOnSite,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  getPlans = async (userProfile, userPackId) => {
    let userType =
      userProfile === "V" || userProfile === "B" || userProfile === "S"
        ? "V"
        : userProfile;
    const response = await list("subscriptionPacks?userType=" + userType);

    if (response.success) {
      let pack = response.data.filter((pack) => pack.id === userPackId);
      this.setState({
        userSubscription: pack[0],
      });
    }
  };

  getLeads = async () => {
    const userId = this.props.match.params && this.props.match.params.id;
    try {
      let pendingList = [];
      let completedList = [];

      const allAlerts = await list(
        "notifications?relatedTo=" + userId + "&isAssigned=true"
      );

      if (allAlerts.success) {
        allAlerts.data.filter((lead) => {
          if (lead.assignedStatus === "completed") {
            return completedList.push(lead);
          } else {
            return pendingList.push(lead);
          }
        });

        this.setState({
          pendingLeads: pendingList,
          completedLeads: completedList,
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  getAllFav = async () => {
    const userId = this.props.match.params && this.props.match.params.id;

    list("userFavorites", { userId: userId })
      .then((totalFav) => {
        this.setState({
          totalFavCount: totalFav.data.length,
        });
      })
      .catch((error) => console.log(error));
  };

  resultView = (type, icon, title, result) => {
    return (
      <Card className="mob-card" hoverable>
        {type === "row" ? (
          <Row justify="space-between">
            <Col span={8}>
              <i
                class={`fa ${icon}`}
                style={{
                  fontSize: "60px",
                  color: "#001528",
                }}
              ></i>
            </Col>
            <Col span={14} offset={2}>
              <div>
                <h2
                  className="sub-title"
                  style={{
                    color: "#001528",
                  }}
                >
                  {title}
                </h2>
                <h1
                  className="result-small"
                  style={{
                    color: "#001528",
                  }}
                >
                  {result}
                </h1>
              </div>
            </Col>
          </Row>
        ) : (
          <div>
            <Col span={24}>
              <h1 style={{ textAlign: "center" }}>
                <i
                  class={`fa ${icon}`}
                  style={{
                    fontSize: "60px",
                    color: "#001528",
                  }}
                ></i>
              </h1>
            </Col>
            <Col span={24}>
              <div>
                <h2
                  className="sub-title"
                  style={{
                    color: "#001528",
                  }}
                >
                  {title}
                </h2>
                <h1
                  className="result-big"
                  style={{
                    color: "#001528",
                  }}
                >
                  {result}
                </h1>
              </div>
            </Col>
          </div>
        )}
      </Card>
    );
  };

  listingHomeView = (allHomesList) => {
    return (
      <Spin spinning={this.state.dataLoadingStatus}>
        {allHomesList && allHomesList.length > 0 ? (
          <Carousel
            itemPosition="CENTER"
            autoPlaySpeed={4000}
            enableAutoPlay
            enableMouseSwipe
            itemsToShow="3"
            itemsToScroll={1}
          >
            {allHomesList.map((home) => (
              <Card
                className="tab-card"
                hoverable
                style={{ width: "100%", height: "100%", margin: "5px" }}
              >
                <h3>
                  {home.propertyDetails2 &&
                    home.propertyDetails2.title &&
                    home.propertyDetails2.title.substring(0, 30)}
                  ...
                </h3>
                <Row gutter={[4, 4]}>
                  <Col span={12}>
                    <h1>
                      $
                      {home.propertyDetails.listPrice &&
                      home.propertyDetails.listPrice
                        ? home.propertyDetails.listPrice
                        : home.propertyDetails.maxPrice &&
                          home.propertyDetails.maxPrice}
                    </h1>
                  </Col>
                  <Col span={12}>
                    <h4 style={{ float: "right" }}>
                      {moment(home.createdAt).format("Do MMM YYYY")}
                    </h4>
                  </Col>
                </Row>

                <Row gutter={[4, 4]}>
                  <Col span={8}>
                    <h1
                      style={{
                        color: "#001528",
                        textAlign: "center",
                      }}
                    >
                      <i class="fa fa-bed"></i>
                    </h1>
                    <h3 style={{ textAlign: "center" }}>
                      {home.propertyFeatures && home.propertyFeatures.beds
                        ? home.propertyFeatures.beds
                        : 0}{" "}
                      Bed(s)
                    </h3>
                  </Col>
                  <Col span={8}>
                    <h1
                      style={{
                        color: "#001528",
                        textAlign: "center",
                      }}
                    >
                      <i class="fa fa-bath"></i>
                    </h1>
                    <h3 style={{ textAlign: "center" }}>
                      {home.propertyFeatures && home.propertyFeatures.baths
                        ? home.propertyFeatures.baths
                        : 0}{" "}
                      Bath(s)
                    </h3>
                  </Col>
                  <Col span={8}>
                    <h1
                      style={{
                        color: "#001528",
                        textAlign: "center",
                      }}
                    >
                      <i class="fa fa-square"></i>
                    </h1>
                    <h3 style={{ textAlign: "center" }}>
                      {home.propertyFeatures && home.propertyFeatures.maxArea
                        ? home.propertyFeatures.maxArea
                        : 0}{" "}
                      Sq.ft
                    </h3>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Button size="large" style={{ width: "100%" }}>
                      <i class="fa fa-eye" style={{ marginRight: "5px" }}></i>
                      {"  "}
                      {home.analytics && home.analytics.listingViews
                        ? home.analytics.listingViews
                        : 0}{" "}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button size="large" style={{ width: "100%" }}>
                      <i class="fa fa-heart" style={{ marginRight: "5px" }}></i>
                      {"  "}
                      {home.analytics && home.analytics.favorites
                        ? home.analytics.favorites
                        : 0}{" "}
                    </Button>
                  </Col>
                </Row>
              </Card>
            ))}
          </Carousel>
        ) : (
          <h2 style={{ textAlign: "center", marginBottom: "0px" }}>
            There is no listing found to show
          </h2>
        )}
      </Spin>
    );
  };

  listingBuyerView = (allBuyersList) => {
    return (
      <Spin spinning={this.state.dataLoadingStatus}>
        {allBuyersList && allBuyersList.length > 0 ? (
          <Carousel
            itemPosition="CENTER"
            autoPlaySpeed={4000}
            enableAutoPlay
            enableMouseSwipe
            itemsToShow="3"
            itemsToScroll={1}
          >
            {allBuyersList.map((buyer) => (
              <Card
                className="tab-card"
                hoverable
                style={{ width: "100%", height: "100%", margin: "5px" }}
              >
                <h3>
                  {buyer.buyerPropertyDetails2 &&
                    buyer.buyerPropertyDetails2.title &&
                    buyer.buyerPropertyDetails2.title.substring(0, 30)}
                  ...
                </h3>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <h1>
                      $
                      {buyer.buyerPropertyDetails.maxPrice &&
                      buyer.buyerPropertyDetails.maxPrice
                        ? buyer.buyerPropertyDetails.maxPrice
                        : "N/A"}
                    </h1>
                  </Col>
                  <Col span={12}>
                    <h4 style={{ float: "right" }}>
                      {moment(buyer.createdAt).format("Do MMM YYYY")}
                    </h4>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <h1
                      style={{
                        color: "#001528",
                        textAlign: "center",
                      }}
                    >
                      <i class="fa fa-bed"></i>
                    </h1>
                    <h3 style={{ textAlign: "center" }}>
                      {buyer.buyerPropertyFeatures &&
                      buyer.buyerPropertyFeatures.beds
                        ? buyer.buyerPropertyFeatures.beds
                        : 0}{" "}
                      Bed(s)
                    </h3>
                  </Col>
                  <Col span={8}>
                    <h1
                      style={{
                        color: "#001528",
                        textAlign: "center",
                      }}
                    >
                      <i class="fa fa-bath"></i>
                    </h1>
                    <h3 style={{ textAlign: "center" }}>
                      {buyer.buyerPropertyFeatures &&
                      buyer.buyerPropertyFeatures.baths
                        ? buyer.buyerPropertyFeatures.baths
                        : 0}{" "}
                      Bath(s)
                    </h3>
                  </Col>
                  <Col span={8}>
                    <h1
                      style={{
                        color: "#001528",
                        textAlign: "center",
                      }}
                    >
                      <i class="fa fa-square"></i>
                    </h1>
                    <h3 style={{ textAlign: "center" }}>
                      {buyer.buyerPropertyFeatures &&
                      buyer.buyerPropertyFeatures.maxArea
                        ? buyer.buyerPropertyFeatures.maxArea
                        : 0}{" "}
                      Sq.ft
                    </h3>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Button size="large" style={{ width: "100%" }}>
                      <i class="fa fa-eye" style={{ marginRight: "5px" }}></i>
                      {"  "}
                      {buyer.analytics && buyer.analytics.listingViews
                        ? buyer.analytics.listingViews
                        : 0}{" "}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button size="large" style={{ width: "100%" }}>
                      <i class="fa fa-heart" style={{ marginRight: "5px" }}></i>
                      {"  "}
                      {buyer.analytics && buyer.analytics.favorites
                        ? buyer.analytics.favorites
                        : 0}{" "}
                    </Button>
                  </Col>
                </Row>
              </Card>
            ))}
          </Carousel>
        ) : (
          <h2 style={{ textAlign: "center", marginBottom: "0px" }}>
            There is no listing found to show
          </h2>
        )}
      </Spin>
    );
  };
  showPropertyStats = (property) => {
    return (
      <div>
        <Row style={{ display: "flex" }}>
          <Col style={{ color: "#aaa", fontSize: "14px", marginRight: "1vw",marginLeft: "40px", }}>
            Property Link :{" "}
            <a
              href={property.propertyLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {property.propertyLink}
            </a>
          </Col>
          <Col style={{ color: "#aaa", fontSize: "14px", marginRight: "1vw",display:"flex" }}>
            Views :
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}> &nbsp; {property.viewCount}</p>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            total duration spent :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
              {property.totalTimeSpentOnProperty?.Hours} Hours{" "}
              {property.totalTimeSpentOnProperty?.Min} min{" "}
            </p>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Last visited :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
             {moment.unix(property.lastVisited).format("lll")}
            </p>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Question asked :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
             {property.questionAsked}
            </p>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Sheduled Time :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
             {property.setSheduledTime}
            </p>
          </Col>
        </Row>
      </div>
    );
  };
  handleCollapseActive = (activeCollapse)=>{
    console.log(activeCollapse);
    this.setState({
      activeCollapse
    })
  }
  render() {
    const {
      analyticsData,
      pendingLeads,
      completedLeads,
      totalFavCount,
      userSubscription,
      visits,
      spendingHours,
      spendingMins,
      averageTimeOnSite,
      dataLoadingStatus,
      propertyStatastics,
    } = this.state;
    return (
      <div className="registration">
        {analyticsData && (
          <div className="filter">
            <Row gutter={[16, 16]} style={{ marginTop: "15px", }}>
              <Col span={24}>
                <Card className="mob-card">
                  <Row justify="space-between">
                    <Col span={6}>
                      <h4 style={{ color: "#aaa" }}>Name :</h4>
                      <h3 style={{ textTransform: "capitalize" }}>
                        {analyticsData &&
                          analyticsData.firstName &&
                          analyticsData.firstName}{" "}
                        {analyticsData &&
                          analyticsData.lastName &&
                          analyticsData.lastName}
                      </h3>

                      {analyticsData.userProfile === "AG" && (
                        <div>
                          <h4 style={{ color: "#aaa" }}>Rating :</h4>
                          <Rate
                            disabled
                            defaultValue={analyticsData.avgRating}
                          />
                        </div>
                      )}

                      <h4 style={{ color: "#aaa" }}>Joined Date :</h4>
                      <h3>
                        {moment(analyticsData.createdAt).format(
                          "MM-DD-YYYY H:M a"
                        )}
                      </h3>
                    </Col>

                    <Col span={6}>
                      <h4 style={{ color: "#aaa" }}>Profile Type :</h4>
                      <h3>
                        {analyticsData.userProfile === "B"
                          ? "Buyer"
                          : analyticsData.userProfile === "S"
                          ? "Seller"
                          : analyticsData.userProfile === "AG"
                          ? "Agent"
                          : analyticsData.userProfile === "V"
                          ? "vendor"
                          : "Lender"}
                      </h3>
                      <h4 style={{ color: "#aaa" }}>Phone # :</h4>
                      <h3>
                        {analyticsData.phone
                          ? analyticsData.phone.toString()
                          : "N/A"}
                      </h3>
                      <h4 style={{ color: "#aaa" }}>E-Mail Id :</h4>
                      <h3>{analyticsData.email}</h3>
                    </Col>

                    {userSubscription ? (
                      <Col span={6}>
                        <h4 style={{ color: "#aaa" }}>Visits # :</h4>
                        <h3>{visits}</h3>

                        <h4 style={{ color: "#aaa" }}>Subscription Info :</h4>
                        <h3>
                          {userSubscription.planTitle
                            ? userSubscription.planTitle
                            : "N/A"}
                          ,{" "}
                          {userSubscription.name
                            ? userSubscription.name
                            : "N/A"}
                        </h3>

                        {analyticsData.userProfile === "AG" && (
                          <h3>
                            Zip Codes :{" "}
                            {userSubscription.zipcodes
                              ? userSubscription.zipcodes
                              : "N/A"}
                            , Specialites :{" "}
                            {userSubscription.specialites
                              ? userSubscription.specialites
                              : "N/A"}
                          </h3>
                        )}

                        {analyticsData.userProfile === "AG" && (
                          <Row>
                            <Col span={12}>
                              <h4 style={{ color: "#aaa" }}>
                                {" "}
                                Available State(s) : ({" "}
                                {analyticsData &&
                                analyticsData.mlsSateList &&
                                analyticsData.mlsSateList.length
                                  ? analyticsData.mlsSateList.length
                                  : 0}{" "}
                                )
                              </h4>
                              <h3>
                                {analyticsData &&
                                analyticsData.mlsSateList &&
                                analyticsData.mlsSateList.length
                                  ? analyticsData.mlsSateList.map(
                                      (s) => s.state + ", "
                                    )
                                  : "N/A"}
                              </h3>
                            </Col>
                            <Col span={12}>
                              <h4 style={{ color: "#aaa" }}>
                                {" "}
                                Invitation Count :
                              </h4>
                              <h3>
                                {analyticsData && analyticsData.invitationCount
                                  ? analyticsData.invitationCount
                                  : 0}
                              </h3>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    ) : (
                      <Col span={6}></Col>
                    )}
                    <Col span={6}>
                      <h4 style={{ color: "#aaa" }}>Total time on site # :</h4>
                      <h3>
                        {spendingHours} {"Hours"} {spendingMins} {"min"}{" "}
                      </h3>
                      <h4 style={{ color: "#aaa" }}>
                        Average time on site # :
                      </h4>
                      <h3>
                        {averageTimeOnSite.avg_hour} {"Hours"}{" "}
                        {averageTimeOnSite.avg_min} {"min"}
                      </h3>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            {propertyStatastics && (
              <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
                <Col span={24}>
                  <Card className="mob-card">
                    <Row justify="space-between">
                      <h3 style={{ textTransform: "capitalize" }}>
                        Properties visited
                      </h3>
                      {propertyStatastics.length > 0 &&
                        propertyStatastics.map((eachStats,index) => (
                          <>
                            <Collapse
                              defaultActiveKey={"1"}
                              // onChange={this.handleCollapseActive}
                            >
                              <Panel header={eachStats.propertyTitle} key={eachStats.key} >
                                {this.showPropertyStats(eachStats)}
                              </Panel>
                              {/* <Panel header="This is panel header 2" key="2">
                                <p>{text}</p>
                              </Panel>
                              <Panel header="This is panel header 3" key="3">
                                <p>{text}</p>
                              </Panel> */}
                            </Collapse>
                           
                          </>
                        ))}
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
            {analyticsData &&
              analyticsData.userProfile === "AG" &&
              analyticsData.newdata && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card className="mob-card">
                      <Row>
                        <table width={"100%"}>
                          <tr>
                            <th className="borderTable">
                              Specialties ({" "}
                              {analyticsData.newdata.Specialties &&
                              analyticsData.newdata.Specialties.length
                                ? analyticsData.newdata.Specialties.length
                                : 0}{" "}
                              )
                            </th>
                            <th className="borderTable">
                              Zip Codes Serviced ({" "}
                              {analyticsData.newdata.zipCodes &&
                              analyticsData.newdata.zipCodes.length
                                ? analyticsData.newdata.zipCodes.length
                                : 0}{" "}
                              )
                            </th>

                            <th className="borderTable">
                              Agent Towns ({" "}
                              {analyticsData.newdata.agentTowns &&
                              analyticsData.newdata.agentTowns.length
                                ? analyticsData.newdata.agentTowns.length
                                : 0}{" "}
                              )
                            </th>

                            <th className="borderTable">
                              Service Areas ({" "}
                              {analyticsData.newdata.serviceAreas &&
                              analyticsData.newdata.serviceAreas.length
                                ? analyticsData.newdata.serviceAreas.length
                                : 0}{" "}
                              )
                            </th>
                          </tr>
                          <tr>
                            <td className="borderTable">
                              <h3>
                                {analyticsData.newdata.Specialties &&
                                  analyticsData.newdata.Specialties.length >
                                    0 &&
                                  analyticsData.newdata.Specialties.join(", ")}
                              </h3>
                            </td>
                            <td className="borderTable">
                              <h3>
                                {analyticsData.newdata.zipCodes &&
                                  analyticsData.newdata.zipCodes.length > 0 &&
                                  analyticsData.newdata.zipCodes.join(", ")}
                              </h3>
                            </td>

                            <td className="borderTable">
                              <h3>
                                {analyticsData.newdata.agentTowns.map(
                                  (area) =>
                                    area.cityName +
                                    "," +
                                    area.stateName +
                                    "," +
                                    area.zipCode +
                                    ". "
                                )}
                              </h3>
                            </td>

                            <td className="borderTable">
                              <h3>
                                {analyticsData.newdata.serviceAreas &&
                                  analyticsData.newdata.serviceAreas.length >
                                    0 &&
                                  analyticsData.newdata.serviceAreas.map(
                                    (area) =>
                                      area.cityName +
                                      "," +
                                      area.stateName +
                                      ". "
                                  )}
                              </h3>
                            </td>
                          </tr>
                        </table>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              )}

            <Row gutter={[16, 16]}>
              <Col span={6}>
                {this.resultView(
                  "column",
                  "fa-heart",
                  "Favorites",
                  totalFavCount
                )}
              </Col>
              <Col span={6}>
                {this.resultView(
                  "column",
                  "fa-search",
                  "Saved Search",
                  analyticsData.userSavedSearch
                    ? analyticsData.userSavedSearch.length
                    : 0
                )}
              </Col>

              <Col span={6}>
                {this.resultView(
                  "column",
                  "fa-home",
                  "Homes",
                  analyticsData.homes ? analyticsData.homes.length : 0
                )}
              </Col>
              <Col span={6}>
                {this.resultView(
                  "column",
                  "fa-users",
                  "Buyers",
                  analyticsData.buyers ? analyticsData.buyers.length : 0
                )}
              </Col>
              {analyticsData &&
                analyticsData.userProfile !== "S" &&
                analyticsData.userProfile !== "B" && (
                  <>
                    <Col span={8}>
                      {this.resultView(
                        "column",
                        "fa-certificate",
                        "Portfolios",
                        analyticsData.portfolio
                          ? analyticsData.portfolio.length
                          : 0
                      )}
                    </Col>

                    <Col span={8}>
                      {this.resultView(
                        "column",
                        "fa-star",
                        "Testimonials",
                        analyticsData.testimonials
                          ? analyticsData.testimonials.length
                          : 0
                      )}
                    </Col>
                  </>
                )}

              {analyticsData && analyticsData.userProfile === "AG" && (
                <>
                  <Col span={8}>
                    {this.resultView(
                      "column",
                      "fa-sitemap",
                      "Total Leads",
                      pendingLeads.length + completedLeads.length
                    )}
                  </Col>
                  <Col span={8}>
                    {this.resultView(
                      "column",
                      "fa-sitemap",
                      "In Progress Leads",
                      pendingLeads.length
                    )}
                  </Col>
                  <Col span={8}>
                    {this.resultView(
                      "column",
                      "fa-sitemap",
                      "Completed Leads",
                      completedLeads.length
                    )}
                  </Col>
                </>
              )}

              {analyticsData && analyticsData.userProfile === "AG" && (
                <Col span={8}>
                  {this.resultView(
                    "column",
                    "fa-usd",
                    "Lenders",
                    analyticsData.lenderInfo
                      ? analyticsData.lenderInfo.length
                      : 0
                  )}
                </Col>
              )}
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card className="mob-card">
                  <Row>
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Home(s)" key="1">
                        {this.listingHomeView(analyticsData.homes)}
                      </TabPane>
                      <TabPane tab="Buyer(s)" key="2">
                        {this.listingBuyerView(analyticsData.buyers)}
                      </TabPane>
                    </Tabs>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        <div className="view-user-details">
          <Spin spinning={dataLoadingStatus} />
        </div>
      </div>
    );
  }
}
export default withRouter(UsersDetails);
